/* eslint-disable */
import React from 'react';
import Layout from '../components/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Experiencetab from '../components/Experiencetab';
import Gettouch from '../components/Gettouch';
import Headinfo from '../components/Headinfo';
import Meeting from '../components/Meeting';
import DataMaturityAssessmentTop from '../components/buildownengagementtop';
import { Helmet } from 'react-helmet';
import Image from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const twoweekengagement = (props) => {
  const twoweekengagementImages = useStaticQuery(graphql`
    query twoweekengagementImages {
      louis: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/louis.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      jay: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/jay.png/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      anton: file(
        absolutePath: {
          regex: "/assets/images/ExperienceUrbian/People/anton.jpg/"
        }
      ) {
        childImageSharp {
          sizes(maxWidth: 250) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <div className='experienceurbianpage duckegg'>
      <Layout>
        <Helmet>
          <title>Urbian | Build your own engagement</title>
        </Helmet>
        <DataMaturityAssessmentTop></DataMaturityAssessmentTop>
        <Container className='spb-5 border-bottom'>
          <Row>
            <Col lg='6'>
              <div className='sp-5'>
                <h3>What is it?</h3>
                <p>
                  Urbian loves to get to grips with the big challenges faced by
                  our clients. We are fired up by complexity, by mission, by
                  scale and by delivering value.
                </p>
                <p>
                  If you’ve got a problem or an opportunity that would benefit
                  from a fresh approach and some expert, cross-function
                  thinking, we’d love to hear about it. We are ready and able to
                  shape a free of charge engagement for the right outcome.
                </p>
              </div>
              <div className='sp-5'>
                <h3>Who is it for?</h3>
                <p>
                  Anyone who thinks they have a ‘Urbian-shaped’ opportunity. If
                  it's got - Product on a mission to deliver value - at its
                  core, we can help.
                </p>
              </div>
              <div className='sp-5'>
                <h3>What will you learn?</h3>
                <ul>
                  <li>Tailor a bespoke engagement to give you what you need</li>
                  <li>
                    Anything from business engagement, through market research
                    and testing to deep tech and data
                  </li>
                  <li>
                    Move ahead confidently with a fresh perspective and a hard
                    plan
                  </li>
                </ul>
              </div>
              <h3>Who will run it?</h3>
              <p>The most appropriate Urbianites, to be agreed.</p>
              <div className='team-grid'>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={
                        twoweekengagementImages.louis.childImageSharp.sizes
                      }
                      alt='Louis Bester'
                    />
                  </div>
                  <h6>Louis Bester</h6>
                  <span>Product Owner</span>
                </div>
                <div>
                  <div class='circle-wrapper'>
                    <Image
                      className='img-fluid'
                      sizes={twoweekengagementImages.jay.childImageSharp.sizes}
                      alt='Jay Thomson'
                    />
                  </div>
                  <h6>Jay Thomson</h6>
                  <span>Managing Partner</span>
                </div>
              </div>
            </Col>
            <Col lg='6'>
              <Meeting location={props.location.pathname}></Meeting>
            </Col>
          </Row>
        </Container>
        <Headinfo></Headinfo>
        <Gettouch></Gettouch>
      </Layout>
    </div>
  );
};
export default twoweekengagement;
